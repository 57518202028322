@use '../../assets/styles/mixins' as *;

.form-container {
  .content {
    h2 {
      color: var(--color-turquoise);
      margin: 0 0 1.25rem;
    }
  }

  .button {
    width: 100%;
    margin-bottom: 0;
  }

  @include up('medium') {
    .button {
      width: auto;
    }
  }

  @include up('large') {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    gap: rem(54px);
    align-items: center;
  }
}
